import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { useLocation } from 'react-router-dom';
import i18n from '../i18n/i18n';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';
import SingleTrainee from '../components/SingleTrainee';
import InternsSkeleton from '../components/InternsSkeleton';
import QRCode from '../components/Drawer/DrawerQRCode';
import DrawerAddIntern from '../components/Drawer/DrawerAddIntern';

// MUI imports
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

// Types
import { SlotData } from '../types/slotData';
import { InternData, InternStatus } from '../types/internData';

// Helpers
import { handleScroll } from '../helpers/functions/handleScroll';
import { useSessionStore } from '../store/SessionStore';
import { getInternById } from '../helpers/api/callIntern';
import { getUserStatusBySlot } from '../helpers/api/callSlot';
import { useUserStore } from '../store/UserStore';

const Interns = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<'top' | 'middle' | 'bottom'>('top');
    const [trainees, setTrainees] = useState<Array<InternData>>([]);
    const [internsSigned, setInternsSigned] = useState<number>(0);
    const [internsAbsent, setInternsAbsent] = useState<number>(0);

    const { session } = useSessionStore();
    const { user } = useUserStore();
    const theme = useTheme();
    const location = useLocation();
    const { selectedSlot } = location.state as { selectedSlot: SlotData };
    const smallScreen = useMediaQuery('(max-height:700px)');

    const linkSession = process.env.REACT_APP_QW_URL + '/evaluation/' + session.sessionToken;

    const colorCountSigned = internsSigned === trainees.length - internsAbsent ? theme.palette.success.main : theme.palette.text.custom;

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedSlot) return;
            try {
                setIsLoading(true);
                const usersStatus = await getUserStatusBySlot(selectedSlot.id);
                if (!usersStatus) return;

                const filteredStatuses = usersStatus.filter((u) => u.userId !== user.id);

                const fetchedTrainees = await Promise.all(
                    session.trainees.map(async (traineeId) => {
                        const trainee = await getInternById(traineeId);
                        if (!trainee) return null;

                        const traineeStatus = filteredStatuses.find((status) => status.userId === trainee.id);

                        return {
                            id: trainee.id,
                            firstname: trainee.firstname ?? '',
                            lastname: trainee.lastname ?? '',
                            hasSigned: traineeStatus?.hasSigned ?? false,
                            isPresent: traineeStatus?.isPresent ?? false,
                            date: traineeStatus?.date ?? null
                        } as InternData;
                    })
                );

                const validTrainees = fetchedTrainees.filter((t): t is InternData => t !== null);
                setTrainees(validTrainees);
                setInternsSigned(validTrainees.filter((trainee) => trainee.hasSigned).length);
                setInternsAbsent(validTrainees.filter((trainee) => !trainee.isPresent).length);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [session]);

    if (isLoading) {
        return <InternsSkeleton nbInterns={session.trainees.length} />;
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '13vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '79%', height: '100%' }}>
                                <Typography sx={{ padding: '0.7em 0', fontSize: '1.1em', color: theme.palette.secondary.main }}>{session.trainingLabel}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '18%',
                                    margin: '0 0.2em',
                                    height: '100%'
                                }}
                            >
                                <QRCode link={linkSession} token={session.sessionToken} />
                                <Typography sx={{ fontWeight: '600', color: theme.palette.text.custom }}>{session.sessionToken}</Typography>
                            </Box>
                        </Box>
                    </ContentInfo>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '5.5vh', margin: '0.5em 0' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>
                                {internsSigned} / {session.trainees.length - internsAbsent}
                            </Typography>
                        </Box>
                        {/* TODO */}
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0 / {session.trainees.length + 1}</Typography>
                        </Box> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>{internsAbsent}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.2em', marginBottom: '0.5em', marginLeft: '1em', color: theme.palette.primary.main }}>{i18n.t('Interns.title')}</Typography>
                    <>
                        {session.trainees.length !== 0 && selectedSlot ? (
                            <>
                                <Box
                                    className="list-container"
                                    onScroll={(event) => handleScroll(event, setScrollPosition)}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '96%',
                                        height: smallScreen ? 'calc(100vh - 6vh - 6vh - 14.5em)' : 'calc(100vh - 6vh - 6vh - 14em)',
                                        overflow: 'auto',
                                        marginLeft: 'auto',
                                        whiteSpace: 'pre'
                                    }}
                                >
                                    {trainees.map((trainee: InternData, index: number) => (
                                        <SingleTrainee
                                            key={trainee.id}
                                            trainee={trainee}
                                            slot={selectedSlot}
                                            customStyle={index < trainees.length - 1 ? true : false}
                                            numberTrainees={trainees.length}
                                        />
                                    ))}
                                </Box>
                                <DrawerAddIntern buttonStatus="floating" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                            </>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 auto', width: '75%', height: '50vh' }}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.2em',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    {i18n.t('Interns.noInterns')}
                                </Typography>
                                <DrawerAddIntern buttonStatus="fixed" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                            </Box>
                        )}
                    </>
                </Box>
            </Box>
        </Box>
    );
};

export default Interns;
