import { useContext } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeContext } from '../contexts/themeContext';
import { PaletteMode } from '@mui/material';

const useTheme = () => {
    const { currentTheme } = useContext(ThemeContext);

    const paletteMode: PaletteMode = currentTheme === 'light' ? 'light' : 'dark';

    const theme = createTheme({
        palette: {
            mode: paletteMode,
            background: {
                default: paletteMode === 'light' ? '#FFFFFF' : '#15202B',
                paper: paletteMode === 'light' ? '#F5F5F5' : '#3F4C59'
            },
            primary: {
                main: paletteMode === 'light' ? '#313131' : '#CACACA'
            },
            secondary: {
                main: paletteMode === 'dark' ? '#FFFFFF' : '#000000'
            },
            text: {
                custom: '#5BB3D9', // SignEval custom color
                primary: paletteMode === 'light' ? '#6A6A6A' : '#B6B6B6',
                secondary: paletteMode === 'light' ? '#FFFFFF' : '#15202B'
            },
            success: {
                main: '#36D476'
            },
            warning: {
                main: '#FFB664'
            },
            error: {
                main: '#FF6642'
            }
        }
    });

    return theme;
};

export { useTheme };
