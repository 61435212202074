import { Box, Typography } from '@mui/material';
import { useTheme } from '../../hooks/useTheme';

// Helpers
import { SlotDayData } from '../../types/slotData';

interface SlotProps {
    slot: SlotDayData;
    isSelected: boolean;
    onClick: () => void;
}

const Slot = ({ slot, isSelected, onClick }: SlotProps) => {
    const theme = useTheme();

    return (
        <Box
            onClick={onClick}
            sx={{
                padding: '4px 0 2px 0',
                mb: '0.4em',
                borderRadius: '5px',
                width: '45px',
                cursor: 'pointer',
                backgroundColor: isSelected ? theme.palette.text.custom : theme.palette.background.default,
                color: isSelected ? theme.palette.background.default : theme.palette.primary.main,
                boxShadow: isSelected ? '0px 3px 7px 0px rgba(0,0,0,0.25)' : 'none'
            }}
        >
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.85rem' }}>{slot.dayName}</Typography>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.15rem' }}>{slot.dayNumber}</Typography>
        </Box>
    );
};

export default Slot;
