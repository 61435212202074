export type SlotData = {
    id: string;
    date?: string;
    startDate: string;
    endDate: string;
    sessionId?: string;
};

export type SlotDayData = {
    dayNumber: number;
    dayName: string;
    firstPeriod?: SlotData;
    secondPeriod?: SlotData;
};

export enum SlotPeriod {
    FIRST_PERIOD = 'firstPeriod',
    SECOND_PERIOD = 'secondPeriod'
}
