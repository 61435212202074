import { useTheme } from '../hooks/useTheme';
import { Link } from 'react-router-dom';
import i18n from '../i18n/i18n';

// MUI imports
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';

// Types
import { SlotData } from '../types/slotData';
import { InternData } from '../types/internData';

interface SignButtonProps {
    signee: InternData;
    selectedSlot: SlotData;
}

const SignButton = ({ signee, selectedSlot }: SignButtonProps) => {
    const theme = useTheme();
    const bgColor = signee.hasSigned ? theme.palette.success.main : theme.palette.text.custom;

    return (
        <Link to={`/sign`} state={{ signee, selectedSlot }} style={{ textDecoration: 'none', width: '100%' }}>
            <IconButton
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0%',
                    backgroundColor: bgColor,
                    color: theme.palette.text.secondary,
                    width: '2.25em',
                    height: '2.25em',
                    marginRight: '17.5px'
                }}
            >
                <GestureOutlinedIcon />
                <Typography sx={{ fontSize: '10px', color: theme.palette.text.secondary }}>{i18n.t('Interns.sign')}</Typography>
            </IconButton>
        </Link>
    );
};

export default SignButton;
