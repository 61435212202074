import { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SignatureCanvas from 'react-signature-canvas';
import i18n from '../i18n/i18n';
import { toast } from 'react-toastify';

// Components
import HeaderBar from '../components/HeaderBar';
import Avatar from '../components/Avatar';
import ContentInfo from '../components/ContentInfo';
import SignEvalButton from '../components/SignEvalButton';

// MUI Imports
import { Box, Typography } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

// Helpers
import { SlotData } from '../types/slotData';
import { InternData } from '../types/internData';
import { SignatureData } from '../types/signatureData';
import { getSignature, postSignature, putSignature, deleteSignature } from '../helpers/api/callSignature';
import { formatSignatureData } from '../helpers/functions/formatSignatureData';

const Sign = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const { selectedSlot, signee: initialSignee } = location.state as { signee: InternData; selectedSlot: SlotData };

    const [signee, setSignee] = useState<InternData>(initialSignee);
    const [signatureData, setSignatureData] = useState<SignatureData | null>(null);
    const [isSigning, setIsSigning] = useState(false);

    const signatureRef = useRef<SignatureCanvas | null>(null);

    useEffect(() => {
        const fetchSignature = async () => {
            try {
                const response = await getSignature(selectedSlot.id, signee.id);
                if (response) {
                    setSignatureData(response);
                    if (response.signature) {
                        signatureRef.current?.fromDataURL(formatSignatureData(response.signature));
                    }
                }
            } catch (error) {
                toast.error(i18n.t('Sign.signatureFetchError'));
                console.error('Erreur lors de la récupération de la signature:', error);
            }
        };

        fetchSignature();
    }, [selectedSlot.id, signee.id, signee.isPresent]);

    const handleSign = useCallback(async () => {
        if (!signatureRef.current) return;

        try {
            const signatureDataUrl = signatureRef.current.toDataURL('image/png');

            const action = signatureData?.signature ? putSignature(signatureData.id, signatureDataUrl, true) : postSignature(selectedSlot.id.toString(), signee.id.toString(), signatureDataUrl, true);

            await action;
            toast.success(i18n.t('Sign.confirmMessageSuccess'));
            navigate(-1);
        } catch (error) {
            toast.error(i18n.t('Sign.confirmMessageError'));
            console.error("Erreur lors de l'enregistrement de la signature:", error);
        }
    }, [signatureData, selectedSlot.id, signee.id]);

    const handleAbsent = useCallback(async () => {
        try {
            const action = signatureData ? putSignature(signatureData.id, null, false) : postSignature(selectedSlot.id.toString(), signee.id.toString(), null, false);

            await action;
            toast.success(i18n.t('Sign.absentMessageSuccess'));

            setSignee((prev) => ({ ...prev, isPresent: false }));
            navigate(-1);
        } catch (error) {
            toast.error(i18n.t('Sign.absentMessageError'));
            console.error("Erreur lors de l'absence de la signature:", error);
        }
    }, [signatureData, selectedSlot.id, signee.id]);

    const handleClearOrDeleteSign = useCallback(async () => {
        try {
            if (!signatureData) {
                signatureRef.current?.clear();
                setIsSigning(false);
                return;
            }

            await deleteSignature(signatureData.id);

            if (!signee.isPresent) {
                setSignatureData(null);
                toast.success(i18n.t('Sign.confirmDeleteAbsentMessageSuccess'));
                setSignee((prev) => ({ ...prev, isPresent: true }));
                return;
            }

            setSignatureData(null);
            signatureRef.current?.clear();
            setIsSigning(false);
            toast.success(i18n.t('Sign.confirmDeleteMessageSuccess'));
        } catch (error) {
            if (!signee.isPresent) {
                toast.error(i18n.t('Sign.confirmDeleteAbsentMessageError'));
            } else {
                toast.error(i18n.t('Sign.confirmDeleteMessageError'));
            }
            console.error('Erreur lors de la suppression de la signature:', error);
        }
    }, [signatureData, signee.isPresent]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100vh',
                alignItems: 'center',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary
            }}
        >
            <HeaderBar />
            <Box sx={{ marginTop: '0.5em', width: '91vw', flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
                <ContentInfo flexDirection="row" justifyContent="flex-start" margin="0 0 0.5em 0" padding="0.75em 0">
                    <Box sx={{ margin: '0 0.25em 0 0.75em' }}>
                        <Avatar firstName={signee.firstname} lastName={signee.lastname} size="small" />
                    </Box>
                    <Typography sx={{ color: theme.palette.secondary.main, fontSize: '1.1em', fontWeight: '600', marginLeft: '0.75em' }}>{`${signee.firstname} ${signee.lastname}`}</Typography>
                </ContentInfo>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '10px',
                        padding: '5px',
                        minHeight: 0,
                        marginTop: '0.5em',
                        boxShadow: theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none',
                        backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59'
                    }}
                >
                    {!signee.isPresent ? (
                        <Typography sx={{ color: theme.palette.error.main, fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center', padding: '1em' }}>{i18n.t('Sign.absent')}</Typography>
                    ) : signatureData?.signature ? (
                        <img src={signatureData.signature} alt="Signature" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    ) : (
                        <SignatureCanvas penColor={theme.palette.primary.main} ref={signatureRef} onBegin={() => setIsSigning(true)} canvasProps={{ style: { width: '100%', height: '100%' } }} />
                    )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '1em 0' }}>
                    <SignEvalButton size="small" backgroundColor="red" endIcon={<DeleteIcon />} onClick={handleClearOrDeleteSign}>
                        {i18n.t('Sign.clear')}
                    </SignEvalButton>
                    <SignEvalButton size="small" backgroundColor="orange" endIcon={<PersonOffOutlinedIcon />} onClick={handleAbsent} disabled={!signee.isPresent}>
                        {i18n.t('Sign.absent')}
                    </SignEvalButton>
                    <SignEvalButton size="small" backgroundColor="green" endIcon={<DrawOutlinedIcon />} onClick={handleSign} disabled={!isSigning}>
                        {i18n.t('Sign.confirm')}
                    </SignEvalButton>
                </Box>
            </Box>
        </Box>
    );
};

export default Sign;
