import { useState } from 'react';
import { useTheme } from '../../hooks/useTheme';
import { format } from 'date-fns';

// MUI imports
import { Box, Button } from '@mui/material';

// Helpers
import { SlotDayData, SlotPeriod } from '../../types/slotData';

interface SlotPeriodPickerProps {
    slot: SlotDayData;
    onPeriodOfDayChange: (period: SlotPeriod.FIRST_PERIOD | SlotPeriod.SECOND_PERIOD) => void;
}

const SlotPeriodPicker = ({ slot, onPeriodOfDayChange }: SlotPeriodPickerProps) => {
    const theme = useTheme();
    const contentBgColor = theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59';
    const contentBoxShadow = theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none';

    const [selectedPeriod, setSelectedPeriod] = useState<SlotPeriod.FIRST_PERIOD | SlotPeriod.SECOND_PERIOD>(SlotPeriod.FIRST_PERIOD);

    const slotStartFirstPeriod = slot.firstPeriod?.startDate ? format(new Date(slot.firstPeriod.startDate), 'HH:mm') : 'N/A';
    const slotEndFirstPeriod = slot.firstPeriod?.endDate ? format(new Date(slot.firstPeriod.endDate), 'HH:mm') : 'N/A';

    const slotStartSecondPeriod = slot.secondPeriod?.startDate ? format(new Date(slot.secondPeriod.startDate), 'HH:mm') : 'N/A';
    const slotEndSecondPeriod = slot.secondPeriod?.endDate ? format(new Date(slot.secondPeriod.endDate), 'HH:mm') : 'N/A';

    const handleSelect = (period: SlotPeriod.FIRST_PERIOD | SlotPeriod.SECOND_PERIOD) => {
        setSelectedPeriod(period);
        onPeriodOfDayChange(period);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                backgroundColor: contentBgColor,
                borderRadius: '10px',
                width: '100%',
                margin: '0 auto 0.6em auto',
                boxShadow: contentBoxShadow
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '6px',
                    left: selectedPeriod == SlotPeriod.FIRST_PERIOD ? '6px' : '50%',
                    width: '47.5%',
                    height: 'calc(100% - 12px)',
                    backgroundColor: theme.palette.text.custom,
                    borderRadius: '8px',
                    transition: 'left 0.3s ease-in-out'
                }}
            />
            <Button
                onClick={() => handleSelect(SlotPeriod.FIRST_PERIOD)}
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: selectedPeriod == SlotPeriod.FIRST_PERIOD ? theme.palette.background.default : theme.palette.primary.main,
                    fontWeight: '700',
                    borderRadius: '10px',
                    padding: '8px 16px',
                    width: '50%',
                    transition: 'color 0.3s ease-in-out'
                }}
            >
                {slotStartFirstPeriod} - {slotEndFirstPeriod}
            </Button>
            <Button
                onClick={() => handleSelect(SlotPeriod.SECOND_PERIOD)}
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: selectedPeriod == SlotPeriod.SECOND_PERIOD ? theme.palette.background.default : theme.palette.primary.main,
                    fontWeight: '700',
                    borderRadius: '10px',
                    padding: '8px 16px',
                    width: '50%',
                    transition: 'color 0.3s ease-in-out'
                }}
            >
                {slotStartSecondPeriod} - {slotEndSecondPeriod}
            </Button>
        </Box>
    );
};

export default SlotPeriodPicker;
