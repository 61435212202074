import { useState, useEffect } from 'react';
import i18n from '../i18n/i18n';
import Link from 'react-router-dom';
import { useTheme } from '../hooks/useTheme';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';
import SignEvalButton from '../components/SignEvalButton';
import DeleteSession from '../components/Modals/DeleteSession';
import SessionSkeleton from '../components/SessionSkeleton';
import QRCode from '../components/Drawer/DrawerQRCode';
import SlotPicker from '../components/Slots/SlotPicker';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';

// Helpers
import { useSessionStore } from '../store/SessionStore';
import { useUserStore } from '../store/UserStore';
import { getSlotsBySessionId } from '../helpers/api/callSlot';
import { formatSlot } from '../helpers/functions/formatSlot';
import { SlotData, SlotDayData } from '../types/slotData';
import { InternData } from '../types/internData';

const Session = () => {
    const [slotsDay, setSlotsDay] = useState<SlotDayData[]>([]);
    const [selectedSlot, setSelectedSlot] = useState<SlotData>();
    const [isLoadingSlots, setIsLoadingSlots] = useState(true);

    const theme = useTheme();
    const locale = i18n.language;
    const { session, loading } = useSessionStore();
    const { user } = useUserStore();
    const signee: InternData = {
        id: user.id || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        hasSigned: false,
        isPresent: false,
        date: ''
    };

    const linkSession = process.env.REACT_APP_QW_URL + '/evaluation/' + session.sessionToken;
    const linkEvalTrainer = process.env.REACT_APP_QW_URL + '/answer/formateur/' + session.id + '/evaluation/';

    useEffect(() => {
        if (!loading) {
            const fetchSlots = async () => {
                setIsLoadingSlots(true);
                const slots = await getSlotsBySessionId(session.id);
                slots && setSlotsDay(formatSlot(slots, locale));
                setIsLoadingSlots(false);
            };

            fetchSlots();
        }
    }, [loading]);

    if (loading || isLoadingSlots) {
        return <SessionSkeleton />;
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%',
                    height: '92.5%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '13vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', height: '100%' }}>
                                <Typography sx={{ padding: '0.7em 0', fontSize: '1.15em', color: theme.palette.secondary.main }}>{session.trainingLabel}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.85em' }}>{session.sessionName}</Typography>
                                    <Typography sx={{ fontSize: '0.85em' }}>{`${session.sessionBegin} - ${session.sessionEnd}`}</Typography>
                                    <Typography sx={{ fontSize: '0.85em' }}>{session.trainerName}</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '18%',
                                    margin: '0 0.2em',
                                    height: '100%'
                                }}
                            >
                                <QRCode link={linkSession} token={session.sessionToken} />
                                <Typography sx={{ fontWeight: '600', color: theme.palette.text.custom }}>{session.sessionToken}</Typography>
                            </Box>
                        </Box>
                    </ContentInfo>
                    {slotsDay.length > 0 && <SlotPicker slots={slotsDay} onSlotChange={setSelectedSlot} />}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '92.5%',
                            height: '45%',
                            maxHeight: '24vh',
                            marginTop: '0.25em'
                        }}
                    >
                        <SignEvalButton
                            route={`/sessions/${session.id}/interns`}
                            linkProps={{ selectedSlot }}
                            variant="contained"
                            size="large"
                            backgroundColor="blue"
                            startIcon={<PeopleAltOutlinedIcon />}
                        >
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.trainees')}</Typography>
                            {session.trainees.length}
                        </SignEvalButton>
                        <SignEvalButton route="/sign" linkProps={{ signee, selectedSlot }} variant="contained" size="large" backgroundColor="blue" startIcon={<GestureOutlinedIcon />}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.signature')}</Typography>
                            &nbsp;
                        </SignEvalButton>
                        <a
                            href={linkEvalTrainer}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textDecoration: 'none', width: '100%' }}
                        >
                            <SignEvalButton variant="contained" size="large" backgroundColor="blue" startIcon={<InventoryOutlinedIcon />}>
                                <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.evaluation')}</Typography>
                                &nbsp;
                            </SignEvalButton>
                        </a>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '92.5%',
                            marginBottom: '3em'
                        }}
                    >
                        <DeleteSession sessionId={session.id} sessionName={session.trainingLabel} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Session;
