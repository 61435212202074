// Types
import { requestHeaders } from './requestHeaders';
import { SignatureData } from '../../types/signatureData';

export const getSignature = async (slotId: string, userId: string): Promise<SignatureData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/signatures?slotId=' + slotId + '&userId=' + userId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];
        return data
            ? {
                  id: data.id,
                  signature: data.signature,
                  isPresent: data.isPresent,
                  date: data.date
              }
            : null;
    } catch (error) {
        console.error('Error getting signature:', error);
        return null;
    }
};

export const postSignature = async (slotId: string, userId: string, signature: string | null, isPresent: boolean): Promise<void> => {
    try {
        await fetch((process.env.REACT_APP_QW_API_URL as string) + '/signatures', {
            method: 'POST',
            headers: requestHeaders(),
            body: JSON.stringify({
                slotId: slotId,
                userId: userId,
                signature: signature,
                isPresent: isPresent
            })
        });
    } catch (error) {
        console.error('Error posting signature:', error);
    }
};

export const putSignature = async (signatureId: string, signature: string | null, isPresent: boolean): Promise<void> => {
    try {
        await fetch((process.env.REACT_APP_QW_API_URL as string) + '/signatures/' + signatureId, {
            method: 'PUT',
            headers: requestHeaders(),
            body: JSON.stringify({
                signature: signature,
                isPresent: isPresent
            })
        });
    } catch (error) {
        console.error('Error putting signature:', error);
    }
};

export const deleteSignature = async (signatureId: string): Promise<void> => {
    try {
        await fetch((process.env.REACT_APP_QW_API_URL as string) + '/signatures/' + signatureId, {
            method: 'DELETE',
            headers: requestHeaders()
        });
    } catch (error) {
        console.error('Error deleting signature:', error);
    }
};
