import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import i18n from '../i18n/i18n';
import { useSessionStore } from '../store/SessionStore';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

// Types
import { SessionData } from '../types/sessionData';

// Helpers
import { truncateText } from '../helpers/functions/truncateText';

interface SessionProps {
    session: SessionData;
    nbSessions: number;
    customStyle: boolean;
}

const Session = ({ session, nbSessions, customStyle }: SessionProps) => {
    const theme = useTheme();
    const { setSession } = useSessionStore();
    const borderColor = theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';

    return (
        <Link to={`/sessions/${session.id}`} style={{ textDecoration: 'none' }} onClick={() => setSession(session)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    width: '92%',
                    height: '90px',
                    padding: '10px 0',
                    backgroundColor: theme.palette.background.default,
                    borderTop: `0.1em ${borderColor} solid`,
                    paddingBottom: customStyle ? 'none' : '2.25em'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '70%' }}>
                    <Typography
                        sx={{
                            minHeight: '2em',
                            color: theme.palette.secondary.main,
                            alignContent: 'center',
                            fontSize: '17px',
                            fontWeight: 'bold'
                        }}
                    >
                        {truncateText(session.trainingLabel, 30)}
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: '15.5px'
                            }}
                        >
                            {truncateText(session.sessionName, 25)}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: '15.5px' }}>{session.sessionBegin}</Typography>
                            <ArrowRightAltIcon sx={{ fontSize: '25px', paddingTop: '1px', margin: '0 5px', color: theme.palette.text.custom }} />
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: '15.5px' }}>{session.sessionEnd}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        width: '30%',
                        height: '100%',
                        color: theme.palette.text.custom
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PeopleAltOutlinedIcon sx={{ fontSize: '1.75em' }} />
                        <Typography sx={{ fontSize: '1.25em', fontWeight: '700', marginLeft: '5px' }}>{Number(session.trainees.length)}</Typography>
                    </Box>
                    <Typography sx={{ fontWeight: '700', fontSize: '1.25em' }}>{session.sessionToken}</Typography>
                </Box>
            </Box>
            {!customStyle && (
                <Typography variant="subtitle1" sx={{ margin: '0 0 1.5em 0.75em', textAlign: 'center', zIndex: 1, color: theme.palette.secondary.main }}>
                    {nbSessions && nbSessions > 1 ? `${nbSessions} ${i18n.t('Sessions.sessions', { count: nbSessions })}` : '1 ' + i18n.t('Sessions.session')}
                </Typography>
            )}
        </Link>
    );
};

export default Session;
