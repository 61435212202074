import { useTheme } from '../hooks/useTheme';
import { Link } from 'react-router-dom';
import i18n from '../i18n/i18n';

// MUI imports
import { Typography, IconButton } from '@mui/material';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';

// Types
import { SlotData } from '../types/slotData';
import { InternData } from '../types/internData';

interface AbsentButtonProps {
    signee: InternData;
    selectedSlot: SlotData;
}

const AbsentButton = ({ signee, selectedSlot }: AbsentButtonProps) => {
    const theme = useTheme();

    return (
        <Link to={`/sign`} state={{ signee, selectedSlot }} style={{ textDecoration: 'none', width: '100%' }}>
            <IconButton
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0%',
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.text.secondary,
                    width: '5.25em',
                    height: '2.25em',
                    marginRight: '17.5px'
                }}
            >
                <PersonOffOutlinedIcon />
                <Typography sx={{ fontSize: '10px', color: theme.palette.text.secondary }}>{i18n.t('Interns.absent')}</Typography>
            </IconButton>
        </Link>
    );
};

export default AbsentButton;
