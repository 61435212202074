import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// MUI imports
import { Box } from '@mui/material';

// Components
import Slot from './Slot';
import SlotPeriodPicker from './SlotPeriodPicker';

// Helpers
import { SlotData, SlotDayData, SlotPeriod } from '../../types/slotData';

interface SlotPickerProps {
    slots: SlotDayData[];
    onSlotChange: (selectedSlot: SlotData) => void;
}

const SlotPicker = ({ slots, onSlotChange }: SlotPickerProps) => {
    const today = new Date().getDate();
    const todaySlot = slots.find((slot) => slot.dayNumber === today);
    const slot = todaySlot || slots[1];

    const [selectedSlotDay, setSelectedSlotDay] = useState<SlotDayData>(slot);
    const [period, setPeriod] = useState<SlotPeriod>(SlotPeriod.FIRST_PERIOD);

    const selectedSlot = selectedSlotDay[period];

    const selectedIndex = slots.findIndex((s) => s.dayNumber === selectedSlotDay.dayNumber);
    const initialSlide = selectedIndex > 0 ? selectedIndex - 1 : 0;

    const settings = {
        className: 'center',
        infinite: false,
        slidesToShow: 6.5,
        swipeToSlide: true,
        accessibility: true,
        speed: 500,
        initialSlide: initialSlide
    };

    useEffect(() => {
        if (selectedSlot) {
            onSlotChange(selectedSlot);
        }
    }, [selectedSlot, onSlotChange]);

    const handleSlotClick = (slot: SlotDayData) => {
        setSelectedSlotDay(slot);
    };

    const handlePeriodOfDayChange = (newPeriod: SlotPeriod) => {
        setPeriod(newPeriod);
    };

    return (
        <>
            <Box className="slider-container" sx={{ width: '100%', margin: '1.1em auto 0.3em auto', overflow: 'hidden' }}>
                <Slider {...settings}>
                    {slots.map((slot, index) => (
                        <Slot key={index} slot={slot} isSelected={selectedSlotDay.dayNumber === slot.dayNumber} onClick={() => handleSlotClick(slot)} />
                    ))}
                </Slider>
            </Box>
            <SlotPeriodPicker slot={selectedSlotDay} onPeriodOfDayChange={handlePeriodOfDayChange} />
        </>
    );
};

export default SlotPicker;
