import { useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import i18n from '../i18n/i18n';

// MUI imports
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

interface EvaluateButtonProps {
    route: string;
    internId: string;
    sessionId: string | undefined;
}

const EvaluateButton = ({ route, internId, sessionId }: EvaluateButtonProps) => {
    const theme = useTheme();
    const [isEvaluate, setIsEvaluate] = useState<boolean>(false);
    const bgColor = isEvaluate ? '#36D476' : theme.palette.text.custom;

    return (
        <a href={route} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', width: '100%' }}>
            <IconButton
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0%',
                    backgroundColor: bgColor,
                    color: theme.palette.text.secondary,
                    width: '2.25em',
                    height: '2.25em',
                    marginRight: '17.5px'
                }}
            >
                <InventoryOutlinedIcon />
                <Typography sx={{ fontSize: '10px', color: theme.palette.text.secondary }}>{i18n.t('Interns.evaluate')}</Typography>
            </IconButton>
        </a>
    );
};

export default EvaluateButton;
