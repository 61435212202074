import { useTheme } from '../hooks/useTheme';
import i18n from '../i18n/i18n';

// Components
import Avatar from './Avatar';
import SignButton from './SignButton';
import EvaluateButton from './EvaluateButton';
import AbsentButton from './AbsentButton';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

// Types
import { InternData } from '../types/internData';
import { SlotData } from '../types/slotData';
import { useSessionStore } from '../store/SessionStore';

interface SingleTraineeProps {
    trainee: InternData;
    slot: SlotData;
    customStyle: boolean;
    numberTrainees: number;
}

const SingleTrainee = ({ trainee, slot, customStyle, numberTrainees }: SingleTraineeProps) => {
    const theme = useTheme();
    const { session } = useSessionStore();

    const linkEvalTrainer = process.env.REACT_APP_QW_URL + '/evaluation/' + session.sessionToken;

    const borderColor = theme.palette.mode === 'light' ? '#EFEFEF' : '#303234';

    if (!trainee) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '7.5px 0',
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.background.default,
                    borderTop: '1px solid #EFEFEF'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Skeleton variant="rectangular" width={50} height={50} sx={{ borderRadius: '50%' }} />
                    <Skeleton variant="text" width={70} height={45} sx={{ margin: '0 10px' }} />
                    <Skeleton variant="text" width={70} height={45} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Skeleton variant="rectangular" width={50} height={50} />
                    <Skeleton variant="rectangular" width={50} height={50} sx={{ margin: '0 17.5px' }} />
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '7.5px 0',
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${borderColor}`,
                    paddingBottom: customStyle ? '7.5px' : '2em'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar firstName={trainee.firstname} lastName={trainee.lastname} size="medium" />
                    <Typography variant="subtitle1" sx={{ marginLeft: '1em' }}>
                        {trainee.firstname} {trainee.lastname}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {trainee.isPresent ? (
                        <>
                            <SignButton signee={trainee} selectedSlot={slot} />
                            <EvaluateButton route={linkEvalTrainer} internId={trainee.id} sessionId={session.id} />
                        </>
                    ) : (
                        <AbsentButton signee={trainee} selectedSlot={slot} />
                    )}
                </Box>
            </Box>
            {!customStyle && (
                <Typography variant="subtitle1" sx={{ paddingBottom: '1em', zIndex: 1, color: theme.palette.text.primary }}>
                    {numberTrainees && numberTrainees > 1 ? `${numberTrainees} ${i18n.t('Interns.interns', { count: numberTrainees })}` : '1 ' + i18n.t('Interns.intern')}
                </Typography>
            )}
        </>
    );
};

export default SingleTrainee;
