import { SlotData, SlotDayData } from '../../types/slotData';

export function formatSlot(slots: SlotData[], locale: string): SlotDayData[] {
    const groupedSlots: { [date: string]: SlotDayData } = {};

    slots.forEach((slot) => {
        const date = new Date(slot.startDate);
        const dayNumber = date.getDate();
        let dayName = date.toLocaleDateString(locale, { weekday: 'short' });

        dayName = dayName.replace(/\.$/, '');

        const key = `${dayNumber}-${dayName}`;

        if (!groupedSlots[key]) {
            groupedSlots[key] = { dayNumber, dayName };
        }

        if (date.getHours() < 12) {
            groupedSlots[key].firstPeriod = slot;
        } else {
            groupedSlots[key].secondPeriod = slot;
        }
    });

    return Object.values(groupedSlots);
}
